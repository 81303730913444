.faqDetails summary::-webkit-details-marker {
  display: none;
}

.faqDetails p {
  display: inline;
  padding-bottom: 1rem;
}
.faqDetails ul {
  margin-left: 2rem;
}
